import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Breadcrumb, Divider, SEO, Text, BookList, Button } from '../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="কুর্‌আন ও হাদীস" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
  কুর্‌আন ও হাদীস
      </Text>
      <Divider mdxType="Divider" />
      <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/study-materials/',
        name: 'শিক্ষাপোকরণ'
      }, {
        url: '/study-materials/quran-and-hadith',
        name: 'কুর্‌আন ও হাদীস',
        isCurrentPage: true
      }]} mdxType="Breadcrumb" />
      <Divider mdxType="Divider" />
      <Text variant="h4" color="primary2" mdxType="Text">
  হাদীস ও দু’আ সম্ভার
      </Text>
      <p>{`ছোটদের জন্য সহজে মুখস্থ রাখা যায় এমন সহজ ও ছোট-ছোট হাদীস আর ‘হিসনুল মুসলিম’ থেকে বাছাই করা দু’আ নিয়ে আমরা করেছি এই ‘হাদীস ও দু’আ সম্ভার’ নামক সংকলনগুলো। A5 কাগজে (A4 এর অর্ধেক) বুকলেট আকারে প্রিন্ট করে বই বানিয়ে দিন।`}</p>
      <Box textAlign="center" mb={3} mdxType="Box">
  <a href="https://drive.google.com/file/d/1OrYRtxFl_lre-pqpbft2FXw8wyO7d0qt/view?usp=sharing" target="_blank" rel="noreferrer noopener">
    <Button variant="primary" mdxType="Button">হাদীস ও দু'আ সম্ভার-১</Button>
  </a>
      </Box>
      <Box textAlign="center" mdxType="Box">
  <a href="https://drive.google.com/file/d/1mAiuxAmX2zgFWya2mKNnrEgv031DKGHF/view?usp=sharing" target="_blank" rel="noreferrer noopener">
    <Button variant="primary" mdxType="Button">হাদীস ও দু'আ সম্ভার-২</Button>
  </a>
      </Box>
      <Box textAlign="center" mt={32} mdxType="Box">
  <a href="https://drive.google.com/drive/folders/1hIpV_XLvBxz9EbViW0OVid9ktrTs2GSD?usp=sharing" target="_blank" rel="noreferrer noopener">
    <Button variant="primary" mdxType="Button">হাদীসগুলোর উচ্চারণ</Button>
  </a>
      </Box>
      <Box textAlign="center" mt={16} mdxType="Box">
  <a href="https://drive.google.com/drive/folders/1S1uFqGDugrLAIoCulJG-BOLzW9cZ9K1l?usp=sharing" target="_blank" rel="noreferrer noopener">
    <Button variant="primary" mdxType="Button">দু'আগুলোর উচ্চারণ</Button>
  </a>
      </Box>
      <Text variant="h4" color="primary2" mdxType="Text">
  কুর্‌আন (মাদানী মুসহাফ)
      </Text>
      <Text mdxType="Text">কুর্‌আন মাশক (ক্বারী আব্দুল ক্বাদীর উসমান এর কণ্ঠে)</Text>
      <Box textAlign="center" mdxType="Box">
  <a href="https://drive.google.com/drive/folders/1f7zYQY2ov_PCy8OD5n-xMytC2huuBlY0?usp=sharing" target="_blank" rel="noreferrer noopener">
    <Button variant="primary" mdxType="Button">জুয আম্মা (৩০তম পারা) ডাউনলোড</Button>
  </a>
      </Box>
      <Box textAlign="center" my={3} mdxType="Box">
  <a href="https://drive.google.com/drive/folders/1CoJNuAxl4ZGeBO983jVUSl_Rysfa2Q7d?usp=sharing" target="_blank" rel="noreferrer noopener">
    <Button variant="primary" mdxType="Button">জুয তাবারাক (২৯তম পারা) ডাউনলোড</Button>
  </a>
      </Box>
      <Box textAlign="center" mt={3} mdxType="Box">
  <a href="https://drive.google.com/file/d/1SUvscfRQdQtZ4QdTTl9kOQ9wBRObOs1t/view?usp=sharing" target="_blank" rel="noreferrer noopener">
    <Button variant="primary" mdxType="Button">সূরা বাক্বারাহ, কাহফ ও মূলক ডাউনলোড</Button>
  </a>
      </Box>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      